<template>
  <div id="shift-layout">
    <KSubMenu
      :menu-items="menuItems"
      :parent-menu="parentMenu"
    />
    <RouterView
      ref="view"
      :get-shift="getShift"
      :shift="shift"
    />
  </div>
</template>

<script>

import Shift from '@/application/models/Shift.js';
import KSubMenu from '@/components/menu/KSubMenu.vue';
import { showMatching } from '@/modules/shift/api/shift.js';

export default {
  name: 'ShiftLayout',
  components: {
    KSubMenu,
  },
  data: () => ({
    shift: null,
  }),
  computed: {
    menuItems() {
      return [
        {
          title: this.$t('global.dossier'),
          route: { name: 'shift.show' },
        },
      ];
    },
    parentMenu() {
      return [
        {
          title: this.$tc('shift.title', 1),
          route: { name: 'shift.index' },
        },
      ];
    },
  },
  watch: {
    '$route.params.shiftId'() {
      this.getShift();
      if (typeof this.$refs.view.reload === 'function') {
        this.$refs.view.reload();
      }
    },
  },
  created() {
    this.getShift();
  },
  methods: {
    async getShift() {
      const response = await showMatching(this.$route.params.shiftId);
      this.shift = new Shift().mapResponse(response.data.data);
    },
  },
};
</script>
